import React from "react";

import styled from "@emotion/styled";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import Paragraph from "@narative/gatsby-theme-novela/src/components/Paragraph";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

import stellar from '../assets/stellar-blockchain.png';
import eos from '../assets/eos-coinbase.png';
import xem from '../assets/xem.png'
import bitcoin from '../assets/bitcoin-blockchain.png';
import ether from '../assets/ether-blockchain.png';
import tezos from '../assets/tezos-coinbase.png';
import dash from '../assets/dash-coinbase.png';


function Coffee() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          
          <Paragraph>
            <Headings.h1>Buy me a Coffee</Headings.h1>
            
            <HoritzontalRule/>  
              <Headings.h2>Paypal</Headings.h2>
              <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JEXAZDQAWFMG6&source=url"><img src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_111x69.jpg" border="0" alt="PayPal Logo" /></a>
            <HoritzontalRule/>

            
            <Headings.h2>Referals</Headings.h2>
            <ul>
              <li><a href="https://brave.com/jav137">Download Brave for a faster browsing with no ads and no trackers</a></li>
            </ul>
            
            <HoritzontalRule/> 
            <Headings.h2>Affiliate Ads</Headings.h2>
            <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=kindlerotating&f=ifr&linkID=a86fcec6e128418a3dfae9e82ca7df1e&t=fonsecaj89-20&tracking_id=fonsecaj89-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" frameborder="0"></iframe>
            <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primegift&banner=0DHKFFV9MZ6GTWBFZK82&f=ifr&linkID=5307e079725501bed58cf7795558c2f2&t=fonsecaj89-20&tracking_id=fonsecaj89-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" frameborder="0"></iframe>
            <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=primeent&banner=1CWN3GGQQ6JG0AFW3W02&f=ifr&linkID=1b3bb0bb8ca4b38c165eae805986e5f8&t=fonsecaj89-20&tracking_id=fonsecaj89-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" frameborder="0"></iframe>
            <div id="amzn-assoc-ad-bccd6f6d-987c-4a32-8c26-9adaa258ccdb"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=bccd6f6d-987c-4a32-8c26-9adaa258ccdb"></script>
            
            <HoritzontalRule/>
            <Headings.h2>Cypto</Headings.h2>

            <WhiteSpace />
            <Headings.h3>Stellar</Headings.h3>
            <img src={stellar} width='300px;' alt="stellar"/>
            <p><strong>GBVS7WNH5KVIUVZJBSD7LPWB7XLZ4KMAQO7HDQEI5QBZR4IQQMYHJIXE</strong></p>

            <WhiteSpace />
            <Headings.h3>EOS</Headings.h3>
            <img src={eos} width='300px;' alt="eos"/>
            <p><strong>coinbasebase</strong></p>
            <p><strong>memo: 860033070</strong></p>

            <WhiteSpace />
            <Headings.h3>XEM</Headings.h3>
            <img src={xem} width='300px;' alt="xem"/>
            <p><strong>NCFTXHZ7MRKLNUZTHQ6JBO5VHJGHGBSYTTG7XJCX</strong></p>

            <WhiteSpace />
            <Headings.h3>Bitcoin</Headings.h3>
            <img src={bitcoin} width='300px;' alt="bitcoin" />
            <p><strong>1GYbrKpwUSuS5aXMGoeufR7qAeuT5c322V</strong></p>

            <WhiteSpace />
            <Headings.h3>Ether</Headings.h3>
            <img src={ether} width='300px;' alt="ether" />
            <p><strong>0x4F9B5CF973305fB4c8bea3F2092c067e986A6BaA</strong></p>

            <WhiteSpace />
            <Headings.h3>Tezos</Headings.h3>
            <img src={tezos} width='300px;' alt="tezos" />
            <p><strong>tz1fB7jx87mSnGJMGmstjaiEtN9APFZX7PD1</strong></p>

            <WhiteSpace />
            <Headings.h3>Dash</Headings.h3>
            <img src={dash} width='300px;' alt="dash" />
            <p><strong>Xh65RppKUkwqNrcZ9jvf9oRstAxB1Xuh87</strong></p>

          </Paragraph>
        </div>
      </Section>
    </Layout>
  );
}

export default Coffee;

const HoritzontalRule = styled.div`
  position: relative;
  margin: 40px auto 50px;
  border-bottom: 1px solid ${p => p.theme.colors.horizontalRule};
  ${mediaqueries.tablet`
    margin: 60px auto;
  `}
  ${mediaqueries.phablet`
    display: none;
  `}
`;


const WhiteSpace = styled.div`
  position: relative;
  margin: 40px auto 50px;
  ${mediaqueries.tablet`
    margin: 60px auto;
  `}
  ${mediaqueries.phablet`
    display: none;
  `}
`;